import {debounce} from "../utils/delays";

export function setEqualHeights(selector) {
    const elements = document.querySelectorAll(selector);

    const maxHeight = [...elements].reduce((max, el) => {
        el.style.height = 'auto';
        return Math.max(max, el.offsetHeight);
    }, 0);

    elements.forEach(el => el.style.height = maxHeight + 'px');
}

export function initEqualHeights(selector) {
    function applyEqualHeights() {
        if (window.innerWidth > 992) {
            setEqualHeights(selector);
        } else {
            const elements = document.querySelectorAll(selector);
            elements.forEach(el => el.style.height = 'auto');
        }
    }

    applyEqualHeights();

    window.addEventListener('resize', debounce(applyEqualHeights, 500));
}

